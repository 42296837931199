import { useState } from "react";

import { SystemProps, Box, css, Grid, Text } from "@storyofams/react-ui";
import { defineMessages, useIntl } from "react-intl";
import { Button, ProductSlider } from "../../../../components";
import { useShopify } from "../../../../context";
import { showToast } from "../../../../lib";
import { ProductCard } from "../../../product/components";
import { SeeMoreButton } from "../molecules/TitleCombo";

type PopularProductsProps = {
  first?: boolean;
  block?: boolean;
  content: {
    title: string;
    anchor_id?: string;
    description?: string;
    link_label?: string;
    link_url?: any;
    products?: any;
    add_to_cart_button?: boolean;
    type: string;
    products_count: string;
    background: any;
  };
} & SystemProps;

const messages = defineMessages({
  addItemsFail:
    "Er ging iets fout bij het toevoegen van producten aan het winkelmandje!",
  addItems: "Voeg producten aan winkelmand toe",
});

export const PopularProducts = ({
  content,
  first,
  ...props
}: PopularProductsProps) => {
  const intl = useIntl();
  const { addItemToCart, updatingCart, toggleCartOverlay } = useShopify();
  const [adding, setAdding] = useState(null);
  const products =
    !!content?.products?.[0] &&
    content?.products?.slice(0, Number(content.products_count));
  return (
    <Box
      width="100%"
      pt={content.background && 5}
      // onClick={() => posthog.capture("Interaction with Popular Tab")}
    >
      {content.background && content.background.filename && (
        <Box
          top={-1}
          zIndex={-1}
          left={0}
          width="100%"
          position="absolute"
          height="750px"
          css={css({
            backgroundImage: `url(${content.background.filename})`,
            backgroundSize: "100% 100%",
          })}
        />
      )}
      {content.type !== "grid" && (
        <ProductSlider
          title={content.title}
          description={content?.description}
          products={
            content.products[0].node
              ? content.products.map(({ node }) => node)
              : content.products
          }
          link={content.link_url}
          linkLabel={content.link_label}
        />
      )}
      {content.type === "grid" && (
        <>
          <Box
            mb={["32px", "40px"]}
            display={"flex"}
            flexDirection={["column", "row"]}
            justifyContent="space-between"
            alignItems={"center"}
            textAlign={["center", "start"]}
            width={"100%"}
          >
            <Box>
              {content.title && (
                <Text
                  lineHeight={"46.8px"}
                  fontFamily={"DINPro"}
                  fontSize={"36px"}
                  fontWeight={700}
                >
                  {content.title}
                </Text>
              )}
              {content?.description && (
                <Text fontSize={"18px"} lineHeight={"25.2px"}>
                  {content?.description}
                </Text>
              )}
            </Box>
            {content.link_label && content.link_url && (
              <SeeMoreButton mt={["20px", 0]} link={content.link_url}>
                {content.link_label}
              </SeeMoreButton>
            )}
          </Box>
          <Grid
            width="100%"
            rowGap={[5]}
            columnGap={[1.5]}
            rowSize={[2]}
            display={{ md: "none" }}
          >
            {!!content?.products?.[0] &&
              products.map((product, index) => (
                <Box width={["48%", "23%"]} key={product?.id}>
                  <ProductCard {...product} index={index} />
                </Box>
              ))}
          </Grid>
          <Grid
            width="100%"
            rowGap={[5]}
            columnGap={[3]}
            rowSize={[4]}
            display={["none", "none", "grid"]}
          >
            {!!content?.products?.[0] &&
              products.map((product, index) => (
                <Box width={["48%", "23%"]} key={product?.id}>
                  <ProductCard {...product} index={index} />
                </Box>
              ))}
          </Grid>
        </>
      )}
      {content?.add_to_cart_button && (
        <Button
          mt={[4, 6]}
          isLoading={updatingCart && adding}
          variant="primary"
          onClick={() => {
            setAdding(true);
            addItemToCart(
              !!content?.products?.[0]?.node
                ? content?.products?.map(({ node }) => ({
                    quantity: 1,
                    merchandiseId: node?.variants?.edges?.[0]?.node?.id,
                  }))
                : content?.products?.map((product) => ({
                    quantity: 1,
                    merchandiseId: product?.variants?.edges?.[0]?.node?.id,
                  })),
              () => {
                setAdding(false);
                toggleCartOverlay(true);
              }
            ).catch(() =>
              showToast(intl.formatMessage(messages.addItemsFail), "error")
            );
          }}
        >
          {intl.formatMessage(messages.addItems)}
        </Button>
      )}
    </Box>
  );
};
